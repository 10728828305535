/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { ContentPageLayout } from '@components/Layout';
import { ReadOnlyForm } from '@components/Form';
import { TextSection } from '@components/TextSection';
import { Modal } from '@components/Modal';
import { Hr, Text, Box, InvisibleButton, Link } from '@primitives';
import { FormRow, ReadonlyFormRow } from '@components/FormRow';
import { observer } from 'mobx-react';
import { ParkingSpaceTable } from '@components/Table'
import { FormattedMessage as M, useIntl } from 'gatsby-plugin-intl';
import { DWELLING, PATHS } from '@constants';
import { useStores } from '@stores';
import styled from 'styled-components';
import { until } from '../../styles';

interface MyInformationDwellingPageProps {
  data: IMarkdownData;
}

const OverviewContainer = styled(Box) <{ hasFloorPlan: boolean }>`
  display: flex;
  width: 100%;
  > * {
    width: ${(props) => props.hasFloorPlan ? '50%' : '100%'};
  }
  ${until('md', `
    flex-direction: column;
    > * {
      width: 100% !important;
    }
  `)};
`;

const FloorPlan = styled.img`
  height: auto;
  width: auto;
  max-width: 250px;
  max-height: 250px;
  margin-left: 1rem;
  ${until('md', `
    margin-left: 0;
    margin-top: 3rem;
    max-height: 400px;
    max-width: 90vw;
  `)};
`;

const FloorPlanModalContent = styled(Box)`
  img {
    max-width: 100%;
  }
  overflow-y: scroll;
  max-height: 70vh;
`;

const FloorPlanContainer = styled(InvisibleButton)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const MyInformationDwellingPage: FC<MyInformationDwellingPageProps> = observer(({
  data: {
    markdownRemark: { frontmatter: { blocks: texts } },
  },
}) => {
  const {
    agreementStore: {
      selectedAgreement: agreement, state, selectedAgreementId, getParkingLots, currentParkingLots, currentUserParkingLots, getCurrentUserParkingLots },
  }: {
    agreementStore: IAgreementStore,
  } = useStores();

  const { formatMessage } = useIntl();
  const [floorPlanModalOpen, setFloorPlanModalOpen] = useState(false);

  useEffect(() => {
    if (selectedAgreementId && !currentParkingLots) {
      getParkingLots(selectedAgreementId);
    }
  }, [getParkingLots, currentParkingLots, selectedAgreementId]);


  useEffect(() => {
    getCurrentUserParkingLots();
  }, [getCurrentUserParkingLots]);

  const getTexts = (id: string) => (texts ? texts.find((block) => block.id === id) : null);

  const isLoading = state === 'Loading';

  const onFloorPlanModalConfirm = () => {
    setFloorPlanModalOpen(false);
  };

  const onFloorPlanClick = () => {
    setFloorPlanModalOpen(true);
  };

  const customRenderer = (field: any) => {
    const parkingLotsString = currentUserParkingLots?.map((parkingLot) => parkingLot.number).join(',');
    return (
      <ReadonlyFormRow id="parking-lots" label="ownParkingLots" value={<Link fontWeight="600" to={PATHS.myInformation.agreement} underline>{parkingLotsString}</Link>} />
    );
  };
  return (
    <ContentPageLayout wideContent>
      <Box mb="2">
        <TextSection title={<M id='fields.label.overview.title'/>} text={<M id='fields.label.overview.text'/>} level={'2'}/>
      </Box>
      <OverviewContainer hasFloorPlan={!!agreement?.dwelling.floor_plan_url}>
        <ReadOnlyForm
          id="dwelling"
          values={agreement}
          fields={DWELLING.general}
          loading={isLoading}
          styles={['singleColumn']}
          customRenderer={customRenderer}
        />
        {agreement?.dwelling.floor_plan_url && (
          <FloorPlanContainer id="floor-plan" onClick={onFloorPlanClick}>
            <FloorPlan src={agreement?.dwelling.floor_plan_url} alt="Pohjapiirustus" />
            <Text fontSize="xs" mt="1"><M id="pages.myInformation.dwelling.openLargeImage" /></Text>
          </FloorPlanContainer>
        )}
      </OverviewContainer>
      <Hr />
      <TextSection title={<M id='fields.label.internetCable.title'/>} level={'3'}/>
      <ReadOnlyForm
        id="internet-cable"
        values={agreement}
        fields={DWELLING.cableInternet}
        loading={isLoading}
        styles={['singleColumn']}
      />
      <Hr />

      <Box mb="2">
      <TextSection title={<M id='fields.label.parkingLots.title'/>} text={<M id='fields.label.parkingLots.text'/>} level={'3'}/>
      </Box>

      <Box mb="2">
        <ReadonlyFormRow id="freeParkingSpacesCount" values={{ freeParkingSpacesCount: currentParkingLots?.vacant.length || 0 }} />
      </Box>

      {!!currentParkingLots?.vacant.length && (
        <Box>
          <Text mt="1" mb="1" color="black" fontWeight="500"><M id="pages.myInformation.numberFreeSpaces" /></Text>
          <ParkingSpaceTable id="freeSpaces" data={currentParkingLots?.vacant} />
        </Box>
      )}

      {!!currentParkingLots?.becomingVacant.length && (
        <Box>
          <Text mt="1" mb="1" color="black" fontWeight="500"><M id="pages.myInformation.numberUpcomingFreeSpaces" /></Text>
          <ParkingSpaceTable id="upComingFreeSpaces" data={currentParkingLots?.becomingVacant} />
        </Box>
      )}
      <Hr />

      <TextSection title={<M id='fields.label.estate.title'/>} level={'3'}/>
      <ReadOnlyForm
        id="estate"
        values={agreement}
        fields={DWELLING.estate}
        loading={isLoading}
      />

      {agreement?.estate.smoking_prohibition && (
        <Box mt="3">
          <FormRow
            id="smoking-prohibition"
            label={formatMessage({ id: 'smokingProhibition' })}
            component={agreement?.estate.smoking_prohibition}
          />
        </Box>
      )}

      {floorPlanModalOpen && (
        <Modal
          title={formatMessage({ id: 'pages.myInformation.dwelling.floorPlan' })}
          primaryButtonText={formatMessage({ id: 'common.close' })}
          onPrimaryClick={onFloorPlanModalConfirm}
        >
          <FloorPlanModalContent>
            <img src={agreement?.dwelling.floor_plan_url} />
          </FloorPlanModalContent>
        </Modal>
      )}
    </ContentPageLayout>
  );
});

export const pageQuery = graphql`
query MyInformationDwellingQuery {
  markdownRemark(frontmatter: {path: {eq: "/me/dwelling/"}}) {
    frontmatter {
      title
      path
      blocks {
        text
        title
        id
      }
    }
  }
}
`;

export default MyInformationDwellingPage;
